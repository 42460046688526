<template>
  <v-container>
    <!-- start spiner --->
    <v-row align="center" class="pt-10" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <v-col cols="12" @click="goToPlaceDetail" class="place-info">
      <div class="d-flex align-center">
        <v-img
          src="https://storage.googleapis.com/keybe/kb.live/img/venues_list_restaurant.svg"
          width="24px"
          max-width="24px"
          height="30px"
          max-height="30px"
          class="mr-4"
        ></v-img>
        <h1>{{venueData.name || venueData.title}}</h1>
      </div>
    </v-col>
    <v-col cols="12" class="product-info">
      <v-row>
        <v-col cols="12" class="pt-0 pb-1">
          <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg']">
            <v-col>
              <h2>{{ selectedPlaceProduct.name }}</h2>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <div
            v-if="selectedPlaceProduct.images && Array.isArray(selectedPlaceProduct.images) && selectedPlaceProduct.images.length"
            :class="['d-flex', 'align-center', 'kbGray']"
          >
            <v-col
              class="flex-grow-0 flex-shrink-1"
            >
              <v-img
                :src="selectedPlaceProduct.images[0]"
                width="92px"
                height="92px"
              ></v-img>
            </v-col>
            <v-col
              class="flex-grow-1 flex-shrink-0"
            >
              <div class="mb-2">{{ selectedPlaceProduct.description }}</div>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <div :class="['d-flex', 'align-center']">
            <v-col class="pa-0">
              <v-list class="py-0">
                <v-list-group
                  class="toppings-list-group"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h2>
                          Proteína extra
                        </h2>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-action>
                      <v-sheet>
                        <v-checkbox color="kbBlue" inset></v-checkbox>
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-title>
                      <h3>
                        Cangrejo
                      </h3>
                    </v-list-item-title>
                    <h3 class="text-no-wrap">
                      $ 3.50
                    </h3>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-sheet>
                        <v-checkbox color="kbBlue" inset></v-checkbox>
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-title>
                      <h3>
                        Pollo
                      </h3>
                    </v-list-item-title>
                    <h3 class="text-no-wrap">
                      $ 2.50
                    </h3>
                  </v-list-item>
                </v-list-group>
                <v-list-group
                  class="toppings-list-group"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h2>
                          Aderezo
                        </h2>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Requerido
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-action>
                      <v-sheet>
                        <v-radio color="kbBlue" inset></v-radio>
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-title>
                      <h3>
                        Cebolla
                      </h3>
                    </v-list-item-title>
                    <h3 class="text-no-wrap">
                      $ 3.50
                    </h3>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-sheet>
                        <v-radio color="kbBlue" inset></v-radio>
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-title>
                      <h3>
                        Aguacate
                      </h3>
                    </v-list-item-title>
                    <h3 class="text-no-wrap">
                      $ 2.50
                    </h3>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-textarea
            :label="$t('addMessage')"
            counter
            solo
            clearable
            auto-grow
            background-color="kbGray"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 d-flex align-center no-wrap">
          <v-btn
            class="mx-2"
            color="kbBlue"
            outlined
            small
            @click="subtractProductFromCart"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            color="kbBlue"
            outlined
            small
            @click="addProductToCart"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <h1 class="ml-5">
            x {{ numberProducts }}
          </h1>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            small
            outlined
            @click="goToPlaceDetail"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="kbBlue"
            small
            outlined
            @click="confirmProductToCart"
          >
            {{ $t('add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  mixins: [],
  async created () {
    try {
      await this.getPlaceInfo()
    } catch (error) {
      console.error(error)
    }
  },
  data () {
    return {
      isLoading: true,
      numberProducts: 1
    }
  },
  computed: {
    ...mapState({
      selectedPlace: (state) => state.places.selectedPlace,
      selectedPlaceId: (state) => state.storedStates.selectedPlaceId,
      selectedPlaceProductId: (state) => state.shoppingCart.selectedPlaceProductId,
      selectedPlaceProduct: (state) => state.shoppingCart.selectedPlaceProduct
    }),
    venueData () {
      return {
        ...this.selectedPlace
      }
    }
  },
  methods: {
    async getPlaceInfo () {
      this.$store.commit('storedStates/SET_SELECTED_VENUE', 'Place')
      const { id, productId } = this.$route.params
      if (id !== this.selectedPlaceId) {
        console.log('id not equals to selectedPlaceId')
        console.log('id')
        console.log(id)
        console.log('this.selectedPlaceId')
        console.log(this.selectedPlaceId)
        await this.$store.commit('storedStates/SET_SELECTED_PLACE_ID', id)
      }
      if (productId !== this.selectedPlaceProductId) {
        console.log('productId not equals to selectedPlaceProductId')
        console.log('id')
        console.log(id)
        console.log('this.selectedPlaceProductId')
        console.log(this.selectedPlaceProductId)
        await this.$store.commit('shoppingCart/SET_SELECTED_PLACE_PRODUCT_ID', productId)
      }
      try {
        await this.$store
          .dispatch('places/getPlace', this.selectedPlaceId)
        await this.$store
          .dispatch('shoppingCart/getProductById')
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    goToPlaceDetail () {
      this.$router.push({
        name: 'VenuePlaceDetail',
        params: { id: this.$route.params.id }
      })
    },
    addProductToCart () {
      this.numberProducts += 1
    },
    subtractProductFromCart () {
      this.numberProducts -= 1
    },
    confirmProductToCart () {
      let product = {
        ...this.selectedPlaceProduct,
        numberProducts: this.numberProducts
      }
      this.$store.commit('shoppingCart/ADD_PRODUCT_TO_CART', product)
      this.goToPlaceDetail()
    }
  },
  watch: {
    async selectedPlaceId (val) {
      await this.getPlaceInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.place-info {
  cursor: pointer;
}
.product-info {
  .toppings-list-group {
    ::v-deep .v-list-group__header {
      background-color: var(--v-kbLittleGray-base);
    }
  }
}
</style>
